import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

const SplitSection = ({ children, image,className,gatsbyImage,classNameLeft,classNameRight }) => {
    return (
        <div className={'flex  m-wrap m-p0 ' + className}>
            <div className={'w-50 h-100 ma m-100 ' + classNameLeft}>
                <GatsbyImage {...gatsbyImage} alt='' className='h-100' image={image?.gatsbyImageData} />
            </div>
            <div className={'w-50 h-100 flex ma m-100  ' + classNameRight}>
                {children}
            </div>

        </div>
    )
}

export default SplitSection