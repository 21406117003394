import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { kebabCase } from 'lodash'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import LineDivider from '../components/LineDivider'
import ConditionallyRender from '../components/conditionallyRender'
import ContentContainer from '../components/contentContainer'
import Header from '../components/header'
import InfoText from '../components/infoText'
import Layout from '../components/layout'
import NextProject from '../components/nextPage'
import ScrollingBannerLarge from '../components/scrollingBannerLarge'
import SEO from '../components/seo'
import Slider from '../components/slider'
import Spacer from '../components/spacer'
import SplitSection from '../components/splitSection'

function TopSection({ ...props }) {
	const { splitSectionTopImage, splitSectionTopTitle, splitSectionTopText } = { ...props }
	return (
		<ConditionallyRender when={splitSectionTopImage && splitSectionTopTitle && splitSectionTopText}>
			<ContentContainer className='pt0 pb0 m-p0'>
				<SplitSection className='pl80 pr80 ' classNameLeft='m-pr20 m-pl20' image={splitSectionTopImage}>
					<InfoText spacer={false} className largeSubTitle={splitSectionTopTitle} paragraph={splitSectionTopText} />
				</SplitSection>
			</ContentContainer>
		</ConditionallyRender>
	)
}

function MiddleSection({ ...props }) {
	const { largeTopImage, imageSplitSectionMiddle, titleSplitSectionMiddle, paragraphSplitSectionMiddle, splitSectionImages, largeImageBottom, videoUrl, sliderImages, secondVideoUrl } = { ...props }

	return (
		<ConditionallyRender when={largeTopImage || imageSplitSectionMiddle || titleSplitSectionMiddle || paragraphSplitSectionMiddle || splitSectionImages || largeImageBottom || videoUrl || sliderImages || secondVideoUrl}>
			<ContentContainer>
				<div className='pl80 pr80 m-p0'>
					<Video url={secondVideoUrl} />
					<Spacer />
					<Video url={videoUrl} />
					<Spacer />
					<Spacer className='m-hide ' />
					<GatsbyImage alt='' image={largeTopImage?.gatsbyImageData} />
					<SplitSection gatsbyImage={{ objectFit: 'contain ' }} image={imageSplitSectionMiddle} className='flex-reverse h-100vh m-h100 m-reverse '>
						<InfoText largeSubTitle={titleSplitSectionMiddle} innerHtml={paragraphSplitSectionMiddle.replaceAll(/(?!^)•/g, '<br/> <br/> •')} className='m-p0' />
					</SplitSection>
					<Spacer className='m-show' />
					<ConditionallyRender when={sliderImages.length > 0}>
						<Slider images={sliderImages?.map(({ url }) => url)} />
					</ConditionallyRender>

					<Spacer className='' />
					<SplitSection classNameRight='pl10  m-p0 ' classNameLeft='pr10 m-p0 m-mb20 ' className='m-mb20' image={splitSectionImages ? splitSectionImages[0] : null}>
						<GatsbyImage alt='' image={splitSectionImages ? splitSectionImages[1]?.gatsbyImageData : null} />
					</SplitSection>
					<Spacer className='m-hide' />
					<GatsbyImage alt='' image={largeImageBottom?.gatsbyImageData} />
				</div>
			</ContentContainer>
		</ConditionallyRender>
	)
}

function BottomSection({ ...props }) {
	const { splitSectionBottomImage, splitSectionBottomParagraph } = { ...props }
	return (
		<ConditionallyRender when={splitSectionBottomImage && splitSectionBottomParagraph}>
			<ContentContainer>
				<SplitSection classNameRight='mta ml0 mr0 mb0 pr20' className='flex-reverse' image={splitSectionBottomImage}>
					<>
						<div>
							<Spacer className='m-show' />
							<div className=' max-600 m-mt40 op-50'>{splitSectionBottomParagraph}</div>
						</div>
					</>
				</SplitSection>
			</ContentContainer>
		</ConditionallyRender>
	)
}

function Video({ url }) {
	const [playing, setPlaying] = useState(false)

	return (
		<ConditionallyRender when={url}>
			<div class='ratio-16-9  m-ratio-2-1'>
				<button onClick={() => setPlaying(state => !state)} class='bg-image video bg-image z-2 bg-video '>
					<ReactPlayer playsinline width='auto' height='100%' playing={playing} url={url} />
					<div class={'bg-image  video ' + (playing ? 'playing' : 'paused')}></div>
				</button>
			</div>
		</ConditionallyRender>
	)
}

const Developments = ({ data: { project } }) => {
	const { title: nextTitle, featureImage: nextImage } = project.nextProject

	const { title, featureImage, location, date: year, about, linkToProjectSite, seo } = project
	return (
		<Layout>
			<SEO title={seo?.title || title} description={seo?.description} image={seo?.image?.url} />
			<Header page='Project' />
			<ScrollingBannerLarge link={linkToProjectSite} year={year} website='https://www.google.com' introduction={about} location={location} image={featureImage} title={title} />
			<TopSection {...project} />
			<LineDivider />
			<MiddleSection {...project} />
			<ConditionallyRender when={project?.splitSectionBottomImage || project?.splitSectionBottomParagraph}>
				<LineDivider />
			</ConditionallyRender>
			<BottomSection {...project} />
			<NextProject title={nextTitle} image={nextImage} link={`/projects/${kebabCase(nextTitle.toLowerCase())}`} />
		</Layout>
	)
}

export default Developments

export const query = graphql`
	query DevelopmentsFeatured($id: String) {
		project: datoCmsProject(id: { eq: $id }) {
			title
			date
			location
			about
			linkToProjectSite
			featureImage {
				gatsbyImageData
			}
			splitSectionTopText
			splitSectionTopImage {
				gatsbyImageData
			}
			splitSectionTopTitle
			largeTopImage {
				gatsbyImageData
			}
			titleSplitSectionMiddle
			paragraphSplitSectionMiddle
			sliderImages {
				url
			}
			imageSplitSectionMiddle {
				gatsbyImageData
			}
			splitSectionImages {
				gatsbyImageData
			}
			largeImageBottom {
				gatsbyImageData
			}
			splitSectionBottomParagraph
			splitSectionBottomImage {
				gatsbyImageData
			}
			secondVideoUrl
			videoUrl
			nextProject {
				featureImage {
					gatsbyImageData
				}
				title
			}
			seo {
				description
				title
				image {
					url
				}
			}
		}
	}
`
